//libraries
import * as React from "react";

const History: React.FC = () => {
  return (
    <div className="bg-history p-8">
      <div className="bg-green mx-auto text-white  max-w-5xl p-4 sm:p-6 md:p-8">
        <h3 className="text-2xl font-bold text-center py-4 sm:py-8">
          History of Seychelles Tennis Association
        </h3>
        <p className="text-justify text-sm sm:text-base mb-4">
          The Seychelles Tennis Association dates back to 1955 when Seychelles
          College, the only Secondary Grammar School for boys at that time run
          by the Brothers of Christian Instruction, had one tennis court in red
          earth or laterite. This court was laboriously carved out of a wooded
          terrace behind the College block thanks to the initiative and tennis
          interest of one member of the Religious Teaching Staff who, together
          with a group of equally motivated students, found time after school
          hours and over the weekend to clear the bush and flatten out the
          rugged surface.
        </p>
        <p className="text-justify text-sm sm:text-base mb-4">
          Once the court was ready, only a handful of Seychelles College
          students took up the game. They were mostly boarders (there was a
          boarding house, then, next to the College mostly for students whose
          parents lived on Praslin and La Digue) and young aspirants to the
          Brotherhood who also lived in another sort of Boarding House run by
          the Brothers themselves.
        </p>
        <p className="text-justify text-sm sm:text-base mb-4">
          The tennis court also provided recreational activity to the Teaching
          Brothers whose residence was just above the court. The only other
          teaching court on the island was found in Victoria near the
          ‘Seychelles Club’ (where Barclays Bank Main branch now stands) to
          which it belonged for the use of its members only. The court was a
          cemented one and nearly fenced and well looked after.
        </p>
        <p className="text-justify text-sm sm:text-base mb-4">
          In 60’s, those who had played tennis while they were at the College
          (the’ tennis pioneers’) and who had finished schooling kept on playing
          as ‘Old Boys’ as an after work sporting activity. It must be
          remembered that there was no formal coaching then. Each one taught
          himself as he played. We can understand therefore why bad tennis
          habits have been picked up by those considered as reasonably good
          players at the moment.
        </p>
        <p className="text-justify text-sm sm:text-base mb-4">
          As the number of tennis players gradually increased, it was felt that
          there was a need for the construction of a second earth court.
          Students and their teachers worked again very hard on a voluntary
          basis, clearing and flattening out a piece of land adjacent to the
          existing court. Around 1960, a tennis committee was set up under the
          Chairmanship of Brother Austin O’Donnell to provide some sort of
          organization to the ‘building sport’ and arrange playing times in
          particular. Owing to our humid wet climate, the quality of the playing
          surface was not good. It became quickly overgrown with grass and
          turned muddy during the rainy season. Maintenance of the courts became
          quite a problem. Members got together and organized fund-raising
          activities such as public dances held in the Seychelles College Hall
          and raffles and fancy fairs. Rs 50,000 were thus raised over a couple
          of months to pay for the cementing of the first court (now the central
          court) undertaken by COSTAIN. One of its directors, Capt. A. Michel,
          and a member of the tennis Association, made us a favour by charging
          us cost price only. Three years later after more funds had been
          raised, we got the second earth court resurfaced with concrete. This
          job was undertaken by Public Works Department thanks to the support of
          two top officials, Mr. Paul Noad and Mr. Hadley, both members of the
          Tennis Association. The second court cost us Rs. 65,000. The third
          court (the closest to the clubhouse) and generally considered as the
          beginners’ court was built fairly recently at the cost of Rs 25,000
          entirely on a self-help basis (the treasurer, Brother Paul Letarte
          doing the lion’s share) with technical guidance from Mr. Vijay Patel
          of Vijay Construction Company, also a member of the Association.
        </p>
        <p className="text-justify text-sm sm:text-base mb-4">
          The STA moved to the newly created tennis complex at Roche Caiman
          where 5 new courts had been built. This change of venue was due to the
          building of the new Mont Fleuri School right on our two concrete
          existing courts. The Chairman, John Adam strongly objected to this and
          asked Education to leave us one court, at least, at the extreme end to
          be used by the children of the new school as a sporting activity. It
          is most unfortunate that the request was not accepted. So, from then
          on, all tennis activities took place at Roche Caiman.
        </p>
      </div>
    </div>
  );
};
export default History;
