import * as React from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}
const Container: React.FC<Props> = ({ className, children }) => {
  return (
    <div className={`${className} max-w-6xl mx-auto px-4 sm:px-6 md:px-8`}>
      {children}
    </div>
  );
};
export default Container;
