import * as React from "react";
import {
  GatsbyImage,
  ImageDataLike,
  StaticImage,
  getImage,
} from "gatsby-plugin-image";

interface AvatarProps {
  name: string;
  image: ImageDataLike;
}
const Avatar: React.FC<AvatarProps> = ({ name, image }) => {
  return (
    <div>
      {image ? (
        <GatsbyImage
          image={getImage(image)}
          alt={name}
          className="w-20 h-20 flex-shrink-0 mx-auto rounded-full"
        />
      ) : (
        <StaticImage
          src="../images/placeholder.jpg"
          alt={name}
          className="w-20 h-20 flex-shrink-0 mx-auto rounded-full"
        />
      )}
    </div>
  );
};
export default Avatar;
