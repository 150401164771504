//libraries
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
//containers
import Container from "../../containers/container";

const people = [
  {
    name: "BROTHER AUSTIN O’CONNELL",
    role: "Co-Founder / CTO",
    period: "1955 - 1961",
    imageUrl: "brother-austin-o'donnell.jpg",
  },
  {
    name: "MR. MAXIME FAYON",
    role: "Co-Founder / CTO",
    period: "1962 - 1967",
    imageUrl: "mr-maxime-fayon.jpg",
  },
  {
    name: "MR. JOHN ADAM",
    role: "Co-Founder / CTO",
    period: "1968 - 1986",
    imageUrl: "JohnAdam.png",
  },
  {
    name: "MR. JOHN ADAM",
    role: "Co-Founder / CTO",
    period: "1987 - 1989",
    imageUrl: "JohnAdam.png",
  },
  {
    name: "MR. PLACIDE ANDRE",
    role: "Co-Founder / CTO",
    period: "1990 - 1992",
    imageUrl: "mr-placide-andre.jpg",
  },
  {
    name: "MR. PLACIDE ANDRE",
    role: "Co-Founder / CTO",
    period: "1993 - 1994",
    imageUrl: "mr-placide-andre.jpg",
  },
  {
    name: "MR. JACQUE MOREL",
    role: "Co-Founder / CTO",
    period: "1995",
    imageUrl: "",
  },
  {
    name: "MR. GUY ROBERT",
    role: "Co-Founder / CTO",
    period: "1996 - 1998",
    imageUrl: "GuyRobert.png",
  },
  {
    name: "MR. GIAN CARLO LAURO",
    role: "Co-Founder / CTO",
    period: "1999 - 2001",
    imageUrl: "mr-gain-carlo-lauro.jpg",
  },
  {
    name: "MR. JOHN ADAM",
    role: "Co-Founder / CTO",
    period: "2002 - 2004",
    imageUrl: "JohnAdam.png",
  },
  {
    name: "MR. PHILIP BRIOCHE",
    role: "Co-Founder / CTO",
    period: "2005 - 2007",
    imageUrl: "PhilipBrioche.jpeg",
  },
  {
    name: "MR. JOHN ADAM",
    role: "Co-Founder / CTO",
    period: "2008 - 2010",
    imageUrl: "JohnAdam.png",
  },
  {
    name: "MR. CHARLIE NG PING CHEUN",
    role: "Co-Founder / CTO",
    period: "2011 - 2012",
    imageUrl: "CharliengPingCheun.jpeg",
  },
  {
    name: "MR. GARRY ALBERT",
    role: "Co-Founder / CTO",
    period: "2013 - 2016",
    imageUrl: "GarryAlbert.jpeg",
  },
  {
    name: "MRS. SARAH RENE",
    role: "Co-Founder / CTO",
    period: "2017 - 2018",
    imageUrl: "SarahRene.jpeg",
  },
  {
    name: "MS. FATIME KANTE",
    role: "Co-Founder / CTO",
    period: "2019 - …",
    imageUrl: "FatimeKante.jpeg",
  },
];

const FormerPresidents: React.FC = () => {
  const getProfileImage = (name: string) => {
    switch (name) {
      case "BROTHER AUSTIN O’CONNELL":
        return (
          <StaticImage
            src="../../images/presidents/brother-austin-o'donnell.jpg"
            alt="BROTHER AUSTIN O’CONNELL"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. MAXIME FAYON":
        return (
          <StaticImage
            src="../../images/presidents/mr-maxime-fayon.jpg"
            alt="MR. MAXIME FAYON"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. JOHN ADAM":
        return (
          <StaticImage
            src="../../images/presidents/john-adam.jpg"
            alt="MR. JOHN ADAM"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. PLACIDE ANDRE":
        return (
          <StaticImage
            src="../../images/presidents/mr-placide-andre.jpg"
            alt="MR. PLACIDE ANDRE"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. PLACIDE ANDRE":
        return (
          <StaticImage
            src="../../images/presidents/mr-placide-andre.jpg"
            alt="MR. PLACIDE ANDRE"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. JACQUE MOREL":
        return (
          <StaticImage
            src="../../images/placeholder.jpg"
            alt="MR. JACQUE MOREL"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. GUY ROBERT":
        return (
          <StaticImage
            src="../../images/presidents/guy-robert.jpg"
            alt="MR. GUY ROBERT"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. GIAN CARLO LAURO":
        return (
          <StaticImage
            src="../../images/presidents/mr-gain-carlo-lauro.jpg"
            alt="MR. GIAN CARLO LAURO"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. PHILIP BRIOCHE":
        return (
          <StaticImage
            src="../../images/presidents/philip-brioche.jpg"
            alt="MR. PHILIP BRIOCHE"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. CHARLIE NG PING CHEUN":
        return (
          <StaticImage
            src="../../images/presidents/charlie-ng-ping-cheun.jpg"
            alt="MR. CHARLIE NG PING CHEUN"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MR. GARRY ALBERT":
        return (
          <StaticImage
            src="../../images/presidents/garry-alert.jpg"
            alt="MR. GARRY ALBERT"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MRS. SARAH RENE":
        return (
          <StaticImage
            src="../../images/presidents/sarah-rene.jpg"
            alt="MRS. SARAH RENE"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
      case "MS. FATIME KANTE":
        return (
          <StaticImage
            src="../../images/presidents/fatime-kante.jpg"
            alt="MS. FATIME KANTE"
            className="mx-auto h-20 w-20 rounded-full"
          />
        );
    }
  };

  return (
    <div className="bg-white py-8 sm:py-16 text-center">
      <Container>
        <div className="space-y-8 sm:space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-center font-semibold leading-8 text-4xl sm:text-4xl md:text-5xl text-blue-500 mb-8 sm:mb-12">
              Former Presidents
            </h2>
            <div className="w-6 h-1 bg-green mb-4 mx-auto" />
            <p className="uppercase text-xs sm:text-sm md:text-base text-blue-500 font-thin">
              Association was founded since 1955
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6"
          >
            {people.map((person) => (
              <li key={person.period}>
                <div className="space-y-4">
                  {person.imageUrl ? (
                    getProfileImage(person.name)
                  ) : (
                    <StaticImage
                      src="../../images/placeholder.jpg"
                      alt={person.name}
                      className="mx-auto h-20 w-20 rounded-full"
                    />
                  )}
                  <div className="space-y-2">
                    <div className="text-xs font-medium lg:text-sm">
                      <h3>{person.name}</h3>
                      <p className="text-green">{person.period}</p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  );
};
export default FormerPresidents;
