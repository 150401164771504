//libraries
import * as React from "react";
//components
import Avatar from "./avatar";
//utils
import findImage from "../utils/findImage";

interface ProfileProps {
  name: string;
  photo: string;
  title: string;
  images: Array<any>;
}

const Profile: React.FC<ProfileProps> = ({ name, photo, title, images }) => {
  const image = findImage(photo, images);
  return (
    <div className="flex-1 flex flex-col p-8">
      <Avatar name={name} image={image} />
      <h3 className="mt-6 text-sm sm:text-base font-medium text-blue-500">
        {name}
      </h3>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-gray-500 text-xs sm:text-sm">{title}</dd>
        <dt className="sr-only">Role</dt>
      </dl>
    </div>
  );
};
export default Profile;
