import { useStaticQuery, graphql } from "gatsby";

export default function useImages() {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile {
          nodes {
            base
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    `
  );
  return images.nodes;
}
