//libraries
import * as React from "react";
import {
  AcademicCapIcon,
  CheckCircleIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";

const supportLinks = [
  {
    name: "VISION",
    description:
      "To become a social hub of choice for everyone to enjoy tennis and to develop the young stars of tomorrow",
    icon: EyeIcon,
  },
  {
    name: "MISSION",
    description:
      "To provide to the community a vibrant club with great facilities, striving to provide tennis at its best where members are welcomed and engaged across all ages and levels to successfully compete locally, regionally and internationally",
    icon: AcademicCapIcon,
  },
];

const VisionMission: React.FC = () => {
  return (
    <div className="bg-white">
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          <StaticImage
            src="../../images/cover.jpeg"
            alt="cover"
            className="w-full h-full object-cover"
          />
          <div
            className="absolute inset-0 bg-gray-500 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
            🇸🇨 ❤️ Tennis
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-white">
            Enjoy tennis with the best coaches and courses on the island
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-6xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div
              key={link.name}
              className="flex flex-col bg-white rounded-2xl shadow-md"
            >
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-green rounded-xl shadow-lg transform -translate-y-1/2">
                  <link.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-xl font-medium text-blue-500">
                  {link.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  {link.description}
                </p>
              </div>
            </div>
          ))}
          <div
            key="CORE VALUES"
            className="flex flex-col bg-white rounded-2xl shadow-md"
          >
            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-green rounded-xl shadow-lg transform -translate-y-1/2">
                <CheckCircleIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </div>
              <h3 className="text-xl font-medium text-gray-900">CORE VALUES</h3>
              <div className="mt-4 text-base text-gray-500">
                <ul>
                  <li className="mb-2">
                    Providing a family friendly facility where all members can
                    be involved
                  </li>
                  <li className="mb-2">
                    Providing a community hub, a space for all members and the
                    wider community
                  </li>
                  <li className="mb-2">
                    Developing actively the game of Tennis for all ages and
                    levels
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default VisionMission;
