//libraries
import * as React from "react";
//containers
import Layout from "../containers/layout";
//components
import Seo from "../components/seo";
import VisionMission from "../components/about/vision-mission";
import CommitteeMembers from "../components/about/committee-members";
import History from "../components/about/history";
import FormerPresidents from "../components/about/former-presidents";

const About: React.FC = () => (
  <Layout>
    <Seo
      title="About"
      description="founders, members and history of seychelles tennis association"
    />
    <VisionMission />
    <History />
    <CommitteeMembers />
    <FormerPresidents />
  </Layout>
);
export default About;
