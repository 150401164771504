//libraries
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
//containers
import Container from "../../containers/container";
//components
import Profile from "../profile";
//hooks
import useImages from "../../hooks/useImages";

interface CommitteeMembersProps {
  name: string;
  photo: string;
  committeeTitle: string;
}

const CommitteeMembers: React.FC = () => {
  const images = useImages();
  const { people } = useStaticQuery(
    graphql`
      query {
        people: allPeopleCsv(filter: { committeeTitle: { ne: "" } }) {
          nodes {
            name
            photo
            committeeTitle
          }
        }
      }
    `
  );

  return (
    <div className="bg-light py-8 sm:py-16 mx-auto">
      <Container>
        <h3 className="text-center font-semibold text-4xl sm:text-4xl md:text-5xl text-blue-500 mb-8 sm:mb-12">
          Executive Committee Members
        </h3>
        <div className="w-6 h-1 mb-4 bg-green mx-auto" />
        <p className="w-3/4 sm:max-w-2xl mx-auto uppercase text-center font-thin text-xs sm:text-sm md:text-base text-blue-400 mb-10 sm:mb-12">
          Our Executive Committee Members comprise as follows:
        </p>
        <div
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-4 md:grid-cols-6"
        >
          {people.nodes.map((person: CommitteeMembersProps, index: number) => (
            <div
              key={person.name}
              className={`col-span-1 sm:col-span-2 flex flex-col text-center rounded-lg shadow-sm border border-gray-200 hover:bg-white transition duration-500 divide-y divide-gray-200 ${
                index === 4 ? "sm:col-start-2 md:col-start-4" : ""
              } ${index === 3 ? "md:col-start-2" : ""}`}
            >
              <Profile
                name={person.name}
                photo={person.photo}
                title={person.committeeTitle}
                images={images}
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default CommitteeMembers;
